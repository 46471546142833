<template>
  <div class="mt-10 px-4">
    <div class="items-center justify-between flex">
      <router-link :to="{ name: 'auth.edit' }" class="flex items-center text-gray-700">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
          <path fill-rule="evenodd"
            d="M15.707 15.707a1 1 0 01-1.414 0l-5-5a1 1 0 010-1.414l5-5a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 010 1.414zm-6 0a1 1 0 01-1.414 0l-5-5a1 1 0 010-1.414l5-5a1 1 0 011.414 1.414L5.414 10l4.293 4.293a1 1 0 010 1.414z"
            clip-rule="evenodd" />
        </svg>
        <span class="text-xs md:text-sm"> Voltar</span>
      </router-link>

      <router-link :to="{

        name: 'products',
        params: { companyUrl: company.url, uuid: company.uuid }
      }" class="flex items-center text-gray-700">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 mx-1" fill="none" viewBox="0 0 24 24"
          stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
            d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6" />
        </svg>
        <span class="text-xs md:text-sm"> ir para página inicial</span>
      </router-link>
    </div>

    <button class="bg-green-400 w-full md:w-1/6 py-3 rounded text-white mt-10" button
      @click.prevent="openModalDeliveryAddress">
      <span class="text-white">Inserir novo</span>
    </button>

    <!--lista endereços-->

    <div class="">
      <div v-if="me.addresses.length > 0" class="py-8 space-y-4" style="flex-grow: 1">
        <div v-for="(address, index) in me.addresses" :key="index" class="
            b-green-700
            border
            rounded
            py-2
            px-4
            cursor-pointer
            flex
            justify-between
            box_address
          ">
          <div class="flex-1">
            <div class="flex justify-between items-center">
              <p class="font-light text-sm text-gray-600">
                {{ address.street }}, {{ address.number }}
              </p>
            </div>

            <p class="font-bold text-sm text-gray-600">
              {{ address.complement }}
            </p>
          </div>

          <div class="bt_delete_address space-x-2">
            <button @click.prevent="openModalDeliveryAddressEdit(address)">
              <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24"
                stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                  d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
              </svg>
            </button>
            <button @click.prevent="deleteAddressClient(address, index)">
              <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-red-600" fill="none" viewBox="0 0 24 24"
                stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                  d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" />
              </svg>
            </button>
          </div>
        </div>
      </div>

      <div v-else class="py-8 space-y-4 flex justify-center items-center" style="flex-grow: 1">
        <div class="flex justify-center flex-col items-center space-y-1">
          <h1 class="font-extrabold text-2xl text-gray-800">OPS,</h1>
          <p class="text-gray-800 text-center">
            Você ainda não tem nenhum endereço cadastrado.
          </p>
          <p class="text-gray-800">Clique abaixo em inserir novo</p>
        </div>
      </div>
    </div>
    <!-- modal novo endereço de entrega-->
    <modal name="delivery-address" :height="'auto'" :adaptive="true">
      <div class="p-5" style="height: inherit; display: flex; flex-direction: column">
        <div class="flex justify-end">
          <button @click.prevent="closeModalDeliveryAddress">❌</button>
        </div>

        <div class="py-2">
          <h4 class="text-gray-700">Cadastre seu endereço:</h4>
        </div>

        <div class="py-8 space-y-4" style="flex-grow: 1">
          <div>
            <input name="neighborhood" type="neighborhood" v-model="formNewAaddress.neighborhood" class="
                appearance-none
                rounded
                relative
                block
                w-full
                px-3
                py-2
                border border-gray-300
                placeholder-gray-500
                text-gray-900
                rounded-t-md
                focus:outline-none
                focus:ring-indigo-500
                focus:border-indigo-500
                focus:z-10
                sm:text-sm
                mb-4
              " placeholder="Bairro" />
            <div v-if="errors.neighborhood != ''" class="text-red-700 text-xs py-1">
              {{ errors.neighborhood[0] || "" }}
            </div>

            <input name="street" type="street" v-model="formNewAaddress.street" class="
                appearance-none
                rounded
                relative
                block
                w-full
                px-3
                py-2
                border border-gray-300
                placeholder-gray-500
                text-gray-900
                rounded-t-md
                focus:outline-none
                focus:ring-indigo-500
                focus:border-indigo-500
                focus:z-10
                sm:text-sm
              " placeholder="Rua" />
            <div v-if="errors.street != ''" class="text-red-700 text-xs py-1">
              {{ errors.street[0] || "" }}
            </div>
          </div>

          <div class="
              flex flex-col
              md:flex-row
              justify-between
              md:space-x-3
              space-y-2
              md:space-y-0
            ">
            <div>
              <input name="number" type="number" v-model="formNewAaddress.number" class="
                  appearance-none
                  rounded
                  relative
                  block
                  px-3
                  py-2
                  border border-gray-300
                  placeholder-gray-500
                  text-gray-900
                  rounded-t-md
                  focus:outline-none
                  focus:ring-indigo-500
                  focus:border-indigo-500
                  focus:z-10
                  sm:text-sm
                " placeholder="Número" />
              <div v-if="errors.number != ''" class="text-red-700 text-xs py-1">
                {{ errors.number[0] || "" }}
              </div>
            </div>

            <input name="complement" type="complement" v-model="formNewAaddress.complement" class="
                appearance-none
                rounded
                relative
                block
                w-full
                px-3
                py-2
                border border-gray-300
                placeholder-gray-500
                text-gray-900
                rounded-t-md
                focus:outline-none
                focus:ring-indigo-500
                focus:border-indigo-500
                focus:z-10
                sm:text-sm
              " placeholder="Complemento" />
          </div>

          <div>
            <input name="reference_point" type="reference_point" v-model="formNewAaddress.reference_point" class="
                appearance-none
                rounded
                relative
                block
                w-full
                px-3
                py-2
                border border-gray-300
                placeholder-gray-500
                text-gray-900
                rounded-t-md
                focus:outline-none
                focus:ring-indigo-500
                focus:border-indigo-500
                focus:z-10
                sm:text-sm
              " placeholder="Ponto de referência" />
          </div>

          <footer class="flex justify-center">
            <button class="bg-green-400 w-4/6 py-4 px-5 rounded text-white" button @click.prevent="createNewAddresses">
              <span>Salvar endereço</span>
            </button>
          </footer>
        </div>
      </div>
    </modal>
    <!-- fim modal-->
    <!-- modal editar endereço de entrega-->
    <modal name="delivery-address-edit" :height="'auto'" :adaptive="true">
      <div class="p-5" style="height: inherit; display: flex; flex-direction: column">
        <div class="flex justify-end">
          <button @click.prevent="closeModalDeliveryAddressEdit">❌</button>
        </div>

        <div class="py-2">
          <h4 class="text-gray-700">Ediatr endereço:</h4>
        </div>

        <div class="py-8 space-y-4">

          <input name="neighborhood" type="neighborhood" v-model="formEditAaddress.neighborhood" class="
                appearance-none
                rounded
                relative
                block
                w-full
                px-3
                py-2
                border border-gray-300
                placeholder-gray-500
                text-gray-900
                rounded-t-md
                focus:outline-none
                focus:ring-indigo-500
                focus:border-indigo-500
                focus:z-10
                sm:text-sm
               
              " placeholder="Bairro" />
          <div v-if="errors.neighborhood != ''" class="text-red-700 text-xs py-1">
            {{ errors.neighborhood[0] || "" }}
          </div>

          <input name="street" type="street" v-model="formEditAaddress.street" class="
                appearance-none
                rounded
                relative
                block
                w-full
                px-3
                py-2
                border border-gray-300
                placeholder-gray-500
                text-gray-900
                rounded-t-md
                focus:outline-none
                focus:ring-indigo-500
                focus:border-indigo-500
                focus:z-10
                sm:text-sm
              " placeholder="Rua" />
          <div v-if="errors.street != ''" class="text-red-700 text-xs py-1">
            {{ errors.street[0] || "" }}
          </div>


          <div class="
              flex flex-col
              md:flex-row
              justify-between
              md:space-x-3
              space-y-2
              md:space-y-0
            ">
            <div>
              <input name="number" type="number" v-model="formEditAaddress.number" class="
                appearance-none
                rounded
                relative
                block
                px-3
                py-2
                border border-gray-300
                placeholder-gray-500
                text-gray-900
                rounded-t-md
                focus:outline-none
                focus:ring-indigo-500
                focus:border-indigo-500
                focus:z-10
                sm:text-sm
              " placeholder="Número" />
              <div v-if="errors.number != ''" class="text-red-700 text-xs py-1">
                {{ errors.number[0] || "" }}
              </div>
            </div>

            <input name="complement" type="complement" v-model="formEditAaddress.complement" class="
                appearance-none
                rounded
                relative
                block
                w-full
                px-3
                py-2
                border border-gray-300
                placeholder-gray-500
                text-gray-900
                rounded-t-md
                focus:outline-none
                focus:ring-indigo-500
                focus:border-indigo-500
                focus:z-10
                sm:text-sm
              " placeholder="Complemento" />
          </div>

          <div>
            <input name="reference_point" type="reference_point" v-model="formEditAaddress.reference_point" class="
                appearance-none
                rounded
                relative
                block
                w-full
                px-3
                py-2
                border border-gray-300
                placeholder-gray-500
                text-gray-900
                rounded-t-md
                focus:outline-none
                focus:ring-indigo-500
                focus:border-indigo-500
                focus:z-10
                sm:text-sm
              " placeholder="Ponto de referência" />
          </div>

          <footer class="flex justify-center">
            <button class="bg-green-400 w-4/6 py-4 px-5 rounded text-white" button @click.prevent="editAddressClient">
              <span>Salvar alteração</span>
            </button>
          </footer>
        </div>
      </div>
    </modal>
    <!-- fim modal-->

    <ModalAlertGeral :isVisible="modalAlertGeral.open" :message="modalAlertGeral.message" :title="modalAlertGeral.title"
      @close-modal-alert="modalAlertGeral.open = false" />
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import ModalAlertGeral from "../../components/ModalAlertGeral.vue";
export default {
  components: {
    ModalAlertGeral,
  },
  data() {
    return {
      modalAlertGeral: {
        open: false,
        message: "",
        title: "",
      },
      formNewAaddress: {
        // form criar novo endereço entrega
        id: null,
        street: "", //rua
        neighborhood: "", // bairro
        number: "",
        complement: "",
        reference_point: "", // ponto de referencia
        default_address: null,
      },
      formEditAaddress: {
        // form editar endereço entrega
        id: null,
        street: "", //rua
        neighborhood: "", // bairro
        number: "",
        complement: "",
        reference_point: "", // ponto de referencia
        default_address: null,
      },
      errors: {
        street: "", //rua
        neighborhood: "", // bairro
        number: "",
        complement: "",
        reference_point: "",
      },
    };
  },
  methods: {
    openModalDeliveryAddressEdit(address) {
      this.reseteErros();
      this.$modal.show("delivery-address-edit");

      // form editar endereço entrega
      (this.formEditAaddress.id = address.id),
        (this.formEditAaddress.street = address.street);
      this.formEditAaddress.neighborhood = address.neighborhood;
      this.formEditAaddress.number = address.number;
      this.formEditAaddress.complement = address.complement;
      this.formEditAaddress.reference_point = address.reference_point;
      this.formEditAaddress.default_address = address.default_address;
    },
    closeModalDeliveryAddressEdit() {
      this.$modal.hide("delivery-address-edit");
      this.resetformEditAaddress();
      this.reseteErros();
    },
    closeModalDeliveryAddress() {
      this.$modal.hide("delivery-address");
      this.reseteErros();
    },
    createNewAddresses() {
      this.reseteErros();
      this.createAddresses(this.formNewAaddress)
        .then(() => {
          this.resetformNewAaddress();
          this.closeModalDeliveryAddress();
          this.reseteErros();
        })
        .catch((error) => {

          this.errors = Object.assign(this.errors, error.response.data.errors);
        });
    },
    editAddressClient() {
      this.reseteErros();
      this.editAddresses(this.formEditAaddress)
        .then(() => {
          this.closeModalDeliveryAddressEdit();
          if (this.me.defaultAddress) {
            this.selectedAddress = this.me.defaultAddress;
          }
        })
        .catch((error) => {
          this.errors = Object.assign(this.errors, error.response.data.errors);
        });
    },
    deleteAddressClient(address) {
      if (this.me.defaultAddress.id === address.id) {
        this.modalAlertGeral.open = true;
        this.modalAlertGeral.message = "Você não pode remover o endereço atual";
        return false;
      }
      this.deleteAddresses(address.id).then((response) => {
        // if (this.selectedAddress.id === response.data.id) {
        //   this.selectedAddress = this.me.defaultAddress;
        // }
      });
    },
    openModalDeliveryAddress() {
      this.reseteErros();
      this.$modal.show("delivery-address");
    },
    resetformNewAaddress() {
      this.formNewAaddress = {
        id: null,
        street: "",
        neighborhood: "",
        number: "",
        complement: "",
        reference_point: "",
        default_address: null,
      };
    },
    resetformEditAaddress() {
      this.formEditAaddress = {
        id: null,
        street: "",
        neighborhood: "",
        number: "",
        complement: "",
        reference_point: "",
        default_address: null,
      };
    },
    reseteErros() {
      this.errors = {
        street: "",
        neighborhood: "",
        number: "",
        complement: "",
        reference_point: "",
      };
    },

    ...mapActions([
      "createOrder",
      "createAddresses",
      "editAddresses",
      "deleteAddresses",
    ]),
  },
  computed: {
    ...mapState({
      me: (state) => state.auth.me,
      company: (state) => state.companies.companySelected,

    }),
  },
};
</script>