<template>
  <div v-if="isOpen">
    <div class="fixed inset-4 z-50 flex justify-center items-center modal">
      <div
        class="bg-white rounded flex items-center flex-col py-3 px-5 relative"
      >
        <div class="flex justify-end w-full">
          <button v-if="!erro" class="" @click="closeModal">
            
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-8 w-8 text-red-600"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
          </button>
        </div>

        <div
          class="
            h-full
            flex flex-col
            items-center
            justify-center
            w-full
            overflow-hidden
            bg-white
            rounded-lg
            dark:bg-gray-800
          "
        >
          <div class="flex-grow flex items-center">
            <div class="flex w-full max-w-sm mx-auto">
              <div class="flex items-center justify-center w-12 bg-yellow-400">
                <svg
                  class="w-6 h-6 text-white fill-current"
                  viewBox="0 0 40 40"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M20 3.33331C10.8 3.33331 3.33337 10.8 3.33337 20C3.33337 29.2 10.8 36.6666 20 36.6666C29.2 36.6666 36.6667 29.2 36.6667 20C36.6667 10.8 29.2 3.33331 20 3.33331ZM21.6667 28.3333H18.3334V25H21.6667V28.3333ZM21.6667 21.6666H18.3334V11.6666H21.6667V21.6666Z"
                  />
                </svg>
              </div>

              <div class="mx-3">
                <span class="font-semibold text-yellow-400 dark:text-yellow-300"
                  >Ops,</span
                >
                <p
                  class="
                    text-xs
                    md:text-sm
                    text-gray-700
                    dark:text-gray-300
                    mb-3
                  "
                >
                  {{ message_ }}
                </p>
              </div>
            </div>
          </div>
        </div>

        <div class="flex justify-end w-full">
          <button
            v-if="erro"
            @click="clearCart"
            class="
              bg-green-400
              px-5
              py-2
              leading-5
              text-white text-xs
              transition-colors
              duration-200
              transform
              rounded
              hover:bg-green-500
              focus:outline-none
            "
            type="button"
          >
            OK
          </button>
          <button
            v-if="!erro"
            @click="closeModal"
            class="
              bg-green-400
              px-5
              py-2
              leading-5
              text-white text-xs
              transition-colors
              duration-200
              transform
              rounded
              hover:bg-green-500
              focus:outline-none
            "
            type="button"
          >
            OK
          </button>
        </div>
      </div>
    </div>
    <div
      class="fixed inset-0 z-40"
      style="background-color: rgba(113, 113, 113, 0.9)"
    ></div>
  </div>
</template>


<script>
import { mapMutations } from "vuex";
export default {
  props: ["isVisible", "title", "message", "erro"],
  data() {
    return {
      isOpen: false,
      title_: "",
      message_: "",
      erro_: false,
    };
  },
  mounted() {
    document.addEventListener("click", this.clickOutListener);
  },
  methods: {
    ...mapMutations(["CLEAR_CART"]),
    clearCart() {
      this.$router.push({ name: "home" });
      this.CLEAR_CART();
      this.erro_ = false
    },
    closeModal() {
      this.$emit("close-modal-alert", false);
    },

    clickOutListener(evt) {
      if (evt.target.classList.contains("modal")) {
        this.closeModal();
      }
    },
  },
  // mounted() {
  //    this.isOpen = this.isVisible
  //     this.message_ = this.message
  //     this.erro_ = this.erro;
  // },
  watch: {
    isVisible() {
      this.isOpen = this.isVisible
      this.message_ = this.message
      this.erro_ = this.erro;
    },
  },
};
</script>

